import Vue from "vue";
import VueRouter from "vue-router";
import UserRole from "../utils/user-roles";
/** layouts **/
import EmptyLayout from "../layoutes/empty.vue";
import SidebarLayout from "../layoutes/with-sidebar.vue";
import store from "../store";
/** views **/
import LoginPage from "../views/login/index.vue";
const ClientsPage = () => import("../views/clients");
const UsersPage = () => import("../views/users");
const ActionsPage = () => import("../views/actions");
const ProjectsPage = () => import("../views/projects");
const LicenseTypesPage = () => import("../views/license-types");
const InstancesPage = () => import("../views/instances");
const LicenseRequestsPage = () => import("../views/license-requests");
const LicensesReportPage = () => import("../views/licences-report");

const Page404 = () => import("../views/404.vue");

Vue.use(VueRouter);

export const defaultRoutesForUsers = {
  [UserRole.admin]: "users",
  [UserRole.manager]: "users",
  [UserRole.distributor]: "projects",
  [UserRole.dealer]: "projects",
  [UserRole.integrator]: "projects",
  [UserRole.user]: "projects",
  [UserRole.support]: "projects",
};

const routes = [
  {
    path: "/",
    name: "root-empty",
    component: EmptyLayout,
    meta: {
      availableFor: [],
    },
    children: [
      {
        name: "login",
        path: "/login",
        component: LoginPage,
      },
    ],
  },
  {
    path: "/",
    name: "root-sidebar",
    component: SidebarLayout,
    meta: {
      availableFor: [],
    },
    children: [
      {
        path: "/users",
        component: UsersPage,
        name: "users",
        meta: {
          availableFor: [
            UserRole.admin,
            UserRole.manager,
            UserRole.distributor,
            UserRole.dealer,
            UserRole.integrator,
            UserRole.user,
            UserRole.support,
          ],
        },
      },
      {
        path: "/actions",
        component: ActionsPage,
        name: "actions",
        meta: {
          availableFor: [
            UserRole.admin,
            UserRole.manager,
            UserRole.distributor,
            UserRole.dealer,
            UserRole.integrator,
            UserRole.user,
            UserRole.support,
          ],
        },
      },
      {
        path: "/projects",
        component: ProjectsPage,
        name: "projects",
        meta: {
          availableFor: [
            UserRole.admin,
            UserRole.manager,
            UserRole.distributor,
            UserRole.dealer,
            UserRole.integrator,
            UserRole.user,
            UserRole.support,
          ],
        },
      },
      {
        path: "/instances",
        component: InstancesPage,
        name: "instances",
        meta: {
          availableFor: [
            UserRole.admin,
            UserRole.manager,
            UserRole.distributor,
            UserRole.dealer,
            UserRole.integrator,
            UserRole.user,
            UserRole.support,
          ],
        },
      },
      {
        path: "/license-types",
        component: LicenseTypesPage,
        name: "licenseTypes",
        meta: {
          availableFor: [
            UserRole.admin,
            UserRole.manager,
            UserRole.distributor,
            UserRole.dealer,
            UserRole.integrator,
            UserRole.user,
            UserRole.support,
          ],
        },
      },
      {
        path: "/clients",
        component: ClientsPage,
        name: "clients",
        meta: {
          availableFor: [
            UserRole.admin,
            UserRole.manager,
            UserRole.distributor,
            UserRole.dealer,
            UserRole.integrator,
            UserRole.user,
            UserRole.support,
          ],
        },
      },
      {
        path: "/license-requests",
        component: LicenseRequestsPage,
        name: "licenseRequests",
        meta: {
          availableFor: [
            UserRole.admin,
            UserRole.manager,
            UserRole.distributor,
            UserRole.dealer,
            UserRole.integrator,
            UserRole.user,
            UserRole.support,
          ],
        },
      },
      {
        path: "/licences/report",
        component: LicensesReportPage,
        name: "licencesReport",
        meta: {
          availableFor: [UserRole.admin],
        },
      },
      {
        path: "*",
        name: "404",
        component: Page404,
        meta: {
          availableFor: [UserRole.admin, UserRole.manager],
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { X: 0, y: 0 };
  },
});

/** auth guard **/
router.beforeEach((to, from, next) => {
  if (store.state.isUserAuthenticated) {
    if (to.name === "login") {
      next({
        name: defaultRoutesForUsers[store.state.userType],
      });
      return;
    } else {
      if (
        to.meta.availableFor &&
        !to.meta.availableFor.includes(store.state.userType)
      ) {
        next({
          name: defaultRoutesForUsers[store.state.userType],
        });
        return;
      }
    }
  } else {
    if (to.name !== "login") {
      next({ name: "login" });
      return;
    }
  }
  window.scrollTo(0, 0);
  next();
});

export default router;
