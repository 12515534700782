const roles = {
  admin: "admin",
  manager: "manager",
  distributor: "distributor",
  dealer: "dealer",
  integrator: "integrator",
  user: "user",
  support: "support",
};
const rolesHierarchy = [
  roles.admin,
  roles.manager,
  roles.distributor,
  roles.dealer,
  roles.integrator,
  roles.user,
  roles.support,
];
// duplicate of internal/service/role-service.go
export const CanCreateUserByRole = (invokerRole, userRole) => {
  const invokerRoleIndex = rolesHierarchy.indexOf(invokerRole);
  const userRoleIndex = rolesHierarchy.indexOf(userRole);
  if (invokerRoleIndex === -1 || userRoleIndex === -1) return false;
  return invokerRoleIndex < userRoleIndex;
};
export const GetAvailableRolesForUserCreate = (role) => {
  const currentRoleIndex = rolesHierarchy.indexOf(role);
  if (currentRoleIndex === -1) return [];
  return rolesHierarchy.slice(currentRoleIndex + 1);
};
export default roles;
