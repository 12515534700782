import endpoints from "./endpoints";
import HttpMethods from "@/utils/http-methods";
import Axios from "axios";
import Helpers from "../../utils/helpers";

const AUTHORIZATION_HTTP_HEADER = "Authorization";
const CONTENT_TYPE_HTTP_HEADER = "content-type";
const CONTENT_TYPE_HTTP_JSON = "application/json;charset=UTF-8";
// const CONTENT_TYPE_HTTP_MULTIPART_FORM = "multipart/form-data";

const SECOND = 1000;
// const MINUTE = SECOND * 60;

const EntityFieldsToApiModel = {
  createdAt: "created_at",
  updatedAt: "updated_at",
  ipAddress: "ip_address",
  expireAt: "expireAt",
  startAt: "start_at",
};

function mapLicenseFeaturesEntityToApiModel(features) {
  return {
    sip: {
      is_enabled: features.sip.isEnabled,
      virtual_numbers: {
        per_account: +features.sip.virtualNumbers.perAccount,
        total: +features.sip.virtualNumbers.total,
      },
      is_web_dialer_enabled: features.sip.isWebDialerEnabled,
      is_sip_trunks_enabled: features.sip.isSipTrunksEnabled,
    },
    mobile_app: {
      is_enabled: features.mobileApp.isEnabled,
      ukey: {
        per_account: +features.mobileApp.ukey.perAccount,
        total: +features.mobileApp.ukey.total,
      },
      is_domain_forward_rule_enabled:
        features.mobileApp.isDomainForwardRuleEnabled,
      is_white_label_enabled: features.mobileApp.isWhiteLabelEnabled,
      is_remote_open_enabled: features.mobileApp.isRemoteOpenEnabled,
      is_siri_enabled: features.mobileApp.isSiriEnabled,
      is_apple_watch_enabled: features.mobileApp.isAppleWatchEnabled,
      is_family_events_enabled: features.mobileApp.isFamilyEventsEnabled,
      ukey2: {
        is_enabled: features.mobileApp.ukey2.isEnabled,
        per_account: +features.mobileApp.ukey2.perAccount,
        total: +features.mobileApp.ukey2.total,
      },
      is_sdk_enabled: features.mobileApp.isSDKEnabled,
    },
    api: {
      is_publicly_available: features.api.isPubliclyAvailable,
    },
    elevator: {
      is_enabled: features.elevator.isEnabled,
    },
    face_id: {
      is_enabled_for_guests: features.faceId.isEnabledForGuests,
    },
    license_plate: {
      is_enabled: features.licensePlate.isEnabled,
    },
    push_server: {
      is_custom: features.pushServer.isCustom,
    },
    amenity: {
      is_enabled: features.amenity.isEnabled,
    },
    integration: {
      axxon_one: {
        is_enabled: features.integration.axxonOne.isEnabled,
      },
      hikvision_anpr: {
        is_enabled: features.integration.hikvisionAnpr.isEnabled,
      },
    },
    identifier: {
      is_guest_enabled: features.identifier.isGuestEnabled,
    },
  };
}

function mapLicenseFeaturesApiModelToEntity(features) {
  return {
    sip: {
      isEnabled: features.sip.is_enabled,
      virtualNumbers: {
        perAccount: features.sip.virtual_numbers.per_account,
        total: features.sip.virtual_numbers.total,
      },
      isWebDialerEnabled: features.sip.is_web_dialer_enabled,
      isSipTrunksEnabled: features.sip.is_sip_trunks_enabled,
    },
    mobileApp: {
      isEnabled: features.mobile_app.is_enabled,
      ukey: {
        perAccount: features.mobile_app.ukey.per_account,
        total: features.mobile_app.ukey.total,
      },
      isDomainForwardRuleEnabled:
        features.mobile_app.is_domain_forward_rule_enabled,
      isWhiteLabelEnabled: features.mobile_app.is_white_label_enabled,
      isRemoteOpenEnabled: features.mobile_app.is_remote_open_enabled,
      isSiriEnabled: features.mobile_app.is_siri_enabled,
      isAppleWatchEnabled: features.mobile_app.is_apple_watch_enabled,
      isFamilyEventsEnabled: features.mobile_app.is_family_events_enabled,
      ukey2: {
        isEnabled: features.mobile_app.ukey2?.is_enabled,
        perAccount: features.mobile_app.ukey2?.per_account,
        total: features.mobile_app.ukey2?.total,
      },
      isSDKEnabled: features.mobile_app.is_sdk_enabled,
    },
    api: {
      isPubliclyAvailable: features.api.is_publicly_available,
    },
    elevator: {
      isEnabled: features.elevator.is_enabled,
    },
    faceId: {
      isEnabledForGuests: features.face_id.is_enabled_for_guests,
    },
    licensePlate: {
      isEnabled: features.license_plate.is_enabled,
    },
    pushServer: {
      isCustom: features.push_server.is_custom,
    },
    amenity: {
      isEnabled: features.amenity.is_enabled,
    },
    integration: {
      axxonOne: {
        isEnabled: features.integration.axxon_one.is_enabled,
      },
      hikvisionAnpr: {
        isEnabled: features.integration.hikvision_anpr.is_enabled,
      },
    },
    identifier: {
      isGuestEnabled: features.identifier.is_guest_enabled,
    },
  };
}

function mapLicenseEntityToApiModel({
  id,
  isEnabled,
  description,
  features,
  startAt,
  expireAt,
  expiringWarningPeriod,
  expiredPeriod,
  prolongationUrl,
  licenseType,
  projectId,
}) {
  return {
    id,
    is_enabled: isEnabled,
    description,
    features: mapLicenseFeaturesEntityToApiModel(features),
    start_at: +startAt,
    expire_at: +expireAt,
    expiring_warning_period: +expiringWarningPeriod,
    expired_period: +expiredPeriod,
    prolongation_url: prolongationUrl,
    license_type_id: licenseType ? licenseType.id : null,
    project_id: projectId,
  };
}

function mapLicenseApiModelToEntity({
  id,
  is_enabled,
  description,
  features,
  start_at,
  expire_at,
  expiring_warning_period,
  expired_period,
  prolongation_url,
  created_at,
  updated_at,
  license_type,
  user,
}) {
  return {
    id,
    isEnabled: is_enabled,
    description,
    features: mapLicenseFeaturesApiModelToEntity(features),
    startAt: start_at,
    expireAt: expire_at,
    expiringWarningPeriod: expiring_warning_period,
    expiredPeriod: expired_period,
    prolongationUrl: prolongation_url,
    createdAt: created_at,
    updatedAt: updated_at,
    licenseType: license_type,
    user,
  };
}

function mapLicenseTypeApiModelToEntity({
  id,
  name,
  description,
  features,
  licenses,
  created_at,
  updated_at,
  whitelisted_users,
}) {
  return {
    id,
    name,
    description,
    whitelistedUsers: whitelisted_users,
    features: mapLicenseFeaturesApiModelToEntity(features),
    licenses,
    createdAt: created_at,
    updatedAt: updated_at,
  };
}

function mapLicenseRequestsApiModelToEntity({
  id,
  license_type_id,
  license_type,
  start_at,
  expire_at,
  apartments_count,
  user_id,
  user,
  project_id,
  project,
  status,
  status_changed_at,
  status_changer_id,
  status_changer,
  created_at,
  updated_at,
}) {
  return {
    id,
    licenseTypeId: license_type_id,
    licenseType: mapLicenseTypeApiModelToEntity(license_type),
    startAt: start_at,
    expireAt: expire_at,
    apartmentsCount: apartments_count,
    userId: user_id,
    user,
    projectId: project_id,
    project,
    status,
    statusChangedAt: status_changed_at,
    statusChangerId: status_changer_id,
    statusChanger: status_changer,
    createdAt: created_at,
    updatedAt: updated_at,
  };
}

function mapLicenseRequestsEntityToApiModel({
  id,
  licenseTypeId,
  licenseType,
  startAt,
  expireAt,
  apartmentsCount,
  userId,
  user,
  projectId,
  project,
  status,
  statusChangedAt,
  statusChangerId,
  statusChanger,
  createdAt,
  updatedAt,
}) {
  return {
    id,
    license_type_id: licenseTypeId,
    license_type: licenseType,
    start_at: startAt,
    expire_at: expireAt,
    apartments_count: apartmentsCount,
    user_id: userId,
    user,
    project_id: projectId,
    project,
    status,
    status_changed_at: statusChangedAt,
    status_changer_id: statusChangerId,
    status_changer: statusChanger,
    created_at: createdAt,
    updated_at: updatedAt,
  };
}

function mapLicenseTypeEntityToApiModel({
  name,
  description,
  features,
  whitelistedUsers,
}) {
  return {
    name,
    description,
    features: mapLicenseFeaturesEntityToApiModel(features),
    whitelisted_users: whitelistedUsers,
  };
}

function mapUserApiModelToEntity({
  id,
  name,
  role,
  login,
  password,
  created_at,
  updated_at,
  email,
  parent_user,
  country,
}) {
  return {
    id,
    name,
    role,
    login,
    password,
    createdAt: created_at,
    updatedAt: updated_at,
    email,
    parent_user,
    country,
  };
}

function mapProjectApiModelToEntity({
  id,
  name,
  description,
  client,
  instance,
  instance_id,
  user,
  licenses,
  created_at,
  updated_at,
}) {
  return {
    id,
    name,
    description,
    client,
    instance: instance ? mapInstanceApiModelToEntity(instance) : null,
    instanceId: instance_id,
    user,
    licenses,
    createdAt: created_at,
    updatedAt: updated_at,
  };
}

function mapInstanceApiModelToEntity({
  id,
  name,
  description,
  ip_address,
  created_at,
  client_info,
}) {
  return {
    id,
    name,
    description,
    ipAddress: ip_address,
    createdAt: created_at,
    clientInfo: client_info ? JSON.parse(client_info) : {},
  };
}

function mapClientApiModelToEntity({
  id,
  name,
  country,
  created_at,
  updated_at,
}) {
  return {
    id,
    name,
    country,
    createdAt: created_at,
    updatedAt: updated_at,
  };
}

function preparePaginationParams({
  pagination: { page, size },
  sorting: { field: sortingField, order: sortingOrder },
  filtering: { field: filteringField, value: filteringValue },
}) {
  const params = { page, size };
  if (sortingField) {
    params.sorting = EntityFieldsToApiModel[sortingField] ?? sortingField;
    // true == desc
    if (sortingOrder) {
      params.sorting = "-" + params.sorting;
    }
  }
  if (filteringField && filteringValue) {
    params.filterField =
      EntityFieldsToApiModel[filteringField] ?? filteringField;
    params.filterValue = encodeURIComponent(filteringValue);
  }
  return params;
}

class BackendHttpApi {
  constructor(authToken) {
    const httpClientConfig = {
      withCredentials: true,
      paramsSerializer: {
        serialize: (params) => {
          let result = "";
          Object.keys(params).forEach((key) => {
            result += `${key}=${params[key]}&`;
          });
          return result.substring(0, result.length - 1);
        },
      },
    };

    this.httpClient = Axios.create(httpClientConfig);
    this.authToken = authToken;
  }

  setAuthToken(authToken) {
    this.authToken = authToken;
  }

  async getCountries() {
    const { data } = await this.request(
      HttpMethods.GET,
      endpoints.info.countries
    );

    return data;
  }

  /**
   * Users block
   * @returns {Promise<void>}
   */
  async getUsers(paginationOptions, sorting, filtering) {
    const {
      data,
      pagination: { page, total },
    } = await this.request(
      HttpMethods.GET,
      endpoints.users.index,
      preparePaginationParams({
        pagination: paginationOptions,
        sorting,
        filtering,
      })
    );

    return {
      items: data.map(mapUserApiModelToEntity),
      totalItems: total,
      page,
    };
  }

  async createUser({
    name,
    role,
    login,
    password,
    email,
    parent_user,
    country,
  }) {
    const { data } = await this.request(
      HttpMethods.PUT,
      endpoints.users.index,
      {},
      {
        name,
        role,
        login,
        password,
        email,
        parent_user_id: parent_user.id,
        country,
      }
    );
    return data;
  }

  async deleteUser(id) {
    await this.request(
      HttpMethods.DELETE,
      Helpers.getParameterizedString(endpoints.users.item, { id }),
      {},
      {}
    );
  }

  async updateUser({
    id,
    name,
    role,
    login,
    password,
    email,
    parent_user,
    country,
  }) {
    const { data } = await this.request(
      HttpMethods.PATCH,
      Helpers.getParameterizedString(endpoints.users.item, { id }),
      {},
      {
        name,
        role,
        login,
        password,
        email,
        parent_user_id: parent_user.id,
        country,
      }
    );
    return data;
  }

  async getUser(id) {
    const {
      data: { name, role, login, password, email, parent_user, country },
    } = await this.request(
      HttpMethods.GET,
      Helpers.getParameterizedString(endpoints.users.item, { id })
    );
    return {
      id,
      name,
      role,
      login,
      password,
      email,
      parent_user,
      country,
    };
  }

  /**
   * Projects block
   */
  async getActions(paginationOptions, sorting, filtering) {
    const {
      data,
      pagination: { page, total },
    } = await this.request(
      HttpMethods.GET,
      endpoints.actions.index,
      preparePaginationParams({
        pagination: paginationOptions,
        sorting,
        filtering,
      })
    );

    return {
      items: data,
      totalItems: total,
      page,
    };
  }

  /**
   * Clients block
   * @returns {Promise<void>}
   */
  async getClients(paginationOptions, sorting, filtering) {
    const {
      data,
      pagination: { page, total },
    } = await this.request(
      HttpMethods.GET,
      endpoints.clients.index,
      preparePaginationParams({
        pagination: paginationOptions,
        sorting,
        filtering,
      })
    );

    return {
      items: data.map(mapClientApiModelToEntity),
      totalItems: total,
      page,
    };
  }

  async createClient({ name, country }) {
    const { data } = await this.request(
      HttpMethods.PUT,
      endpoints.clients.index,
      {},
      {
        name,
        country,
      }
    );
    return data;
  }

  async deleteClient(id) {
    await this.request(
      HttpMethods.DELETE,
      Helpers.getParameterizedString(endpoints.clients.item, { id }),
      {},
      {}
    );
  }

  async updateClient({ id, name, country }) {
    const { data } = await this.request(
      HttpMethods.PATCH,
      Helpers.getParameterizedString(endpoints.clients.item, { id }),
      {},
      {
        name,
        country,
      }
    );
    return data;
  }

  async getClient(id) {
    const {
      data: { name, country },
    } = await this.request(
      HttpMethods.GET,
      Helpers.getParameterizedString(endpoints.clients.item, { id })
    );

    return {
      id,
      name,
      country,
    };
  }

  /**
   * Instances block
   * @returns {Promise<void>}
   */
  async getInstances(paginationOptions, sorting, filtering) {
    const {
      data,
      pagination: { page, total },
    } = await this.request(
      HttpMethods.GET,
      endpoints.instances.index,
      preparePaginationParams({
        pagination: paginationOptions,
        sorting,
        filtering,
      })
    );

    return {
      items: data.map(mapInstanceApiModelToEntity),
      totalItems: total,
      page,
    };
  }

  /**
   * License types block
   */
  async getLicenseRequests(paginationOptions, sorting, filtering) {
    const {
      data,
      pagination: { page, total },
    } = await this.request(
      HttpMethods.GET,
      endpoints.licenseRequests.index,
      preparePaginationParams({
        pagination: paginationOptions,
        sorting,
        filtering,
      })
    );

    return {
      items: data.map(mapLicenseRequestsApiModelToEntity),
      totalItems: total,
      page,
    };
  }

  async getLicenseRequest(id) {
    const { data } = await this.request(
      HttpMethods.GET,
      Helpers.getParameterizedString(endpoints.licenseRequests.item, { id }),
      {},
      {}
    );

    return mapLicenseRequestsApiModelToEntity(data);
  }

  async createLicenseRequest({
    licenseType,
    startAt,
    expireAt,
    apartmentsCount,
    project,
  }) {
    const { data } = await this.request(
      HttpMethods.PUT,
      endpoints.licenseRequests.index,
      {},
      mapLicenseRequestsEntityToApiModel({
        licenseTypeId: licenseType.id,
        startAt,
        expireAt,
        apartmentsCount,
        projectId: project.id,
      })
    );
    return data;
  }

  async deleteLicenseRequest(id) {
    await this.request(
      HttpMethods.DELETE,
      Helpers.getParameterizedString(endpoints.licenseRequests.item, { id }),
      {},
      {}
    );
  }

  async updateLicenseRequest({
    id,
    licenseType,
    startAt,
    expireAt,
    apartmentsCount,
    project,
  }) {
    const { data } = await this.request(
      HttpMethods.PATCH,
      Helpers.getParameterizedString(endpoints.licenseRequests.item, { id }),
      {},
      mapLicenseRequestsEntityToApiModel({
        id,
        licenseTypeId: licenseType.id,
        startAt,
        expireAt,
        apartmentsCount,
        projectId: project.id,
      })
    );
    return data;
  }

  async changeStatusLicenseRequest({ id, status }) {
    const { data } = await this.request(
      HttpMethods.PATCH,
      Helpers.getParameterizedString(endpoints.licenseRequests.changeStatus, {
        id,
      }),
      {},
      {
        status,
      }
    );
    return data;
  }

  /**
   * License types block
   */
  async getLicenseTypes(paginationOptions, sorting, filtering) {
    const {
      data,
      pagination: { page, total },
    } = await this.request(
      HttpMethods.GET,
      endpoints.licenseTypes.index,
      preparePaginationParams({
        pagination: paginationOptions,
        sorting,
        filtering,
      })
    );

    return {
      items: data.map(mapLicenseTypeApiModelToEntity),
      totalItems: total,
      page,
    };
  }

  async createLicenseType({ name, description, features, whitelistedUsers }) {
    const { data } = await this.request(
      HttpMethods.PUT,
      endpoints.licenseTypes.index,
      {},
      mapLicenseTypeEntityToApiModel({
        name,
        description,
        features,
        whitelistedUsers,
      })
    );
    return data;
  }

  async deleteLicenseType(id) {
    await this.request(
      HttpMethods.DELETE,
      Helpers.getParameterizedString(endpoints.licenseTypes.item, { id }),
      {},
      {}
    );
  }

  async updateLicenseType({
    id,
    name,
    description,
    features,
    whitelistedUsers,
  }) {
    const { data } = await this.request(
      HttpMethods.PATCH,
      Helpers.getParameterizedString(endpoints.licenseTypes.item, { id }),
      {},
      mapLicenseTypeEntityToApiModel({
        name,
        description,
        features,
        whitelistedUsers,
      })
    );
    return data;
  }

  async getLicenseType(id) {
    const {
      data: { name, description, features, whitelisted_users },
    } = await this.request(
      HttpMethods.GET,
      Helpers.getParameterizedString(endpoints.licenseTypes.item, { id })
    );

    return mapLicenseTypeApiModelToEntity({
      id,
      name,
      description,
      features,
      whitelisted_users,
    });
  }

  /**
   * Authentication block
   * @returns {Promise<{access: *, permissions: *, refresh: *, userType: *, userId: *}>}
   */
  async login(login, password) {
    const {
      data: { access, refresh, role, permissions, user_id },
    } = await this.request(
      HttpMethods.POST,
      endpoints.auth.login,
      {},
      {
        login,
        password,
      }
    );
    this.setAuthToken(access);
    return { access, refresh, userType: role, permissions, userId: user_id };
  }

  async logout() {
    await this.request(HttpMethods.POST, endpoints.auth.logout);
  }

  /**
   * Projects block
   */
  async getProjects(paginationOptions, sorting, filtering) {
    const {
      data,
      pagination: { page, total },
    } = await this.request(
      HttpMethods.GET,
      endpoints.projects.index,
      preparePaginationParams({
        pagination: paginationOptions,
        sorting,
        filtering,
      })
    );

    return {
      items: data.map(mapProjectApiModelToEntity),
      totalItems: total,
      page,
    };
  }

  async createProject({
    name,
    description,
    client,
    instanceId,
    instanceIpAddress,
    user,
  }) {
    const { data } = await this.request(
      HttpMethods.PUT,
      endpoints.projects.index,
      {},
      {
        name,
        description,
        client_id: client.id,
        instance_id: instanceId,
        instance_ip_address: instanceIpAddress,
        user_id: user.id,
      }
    );
    return data;
  }

  async deleteProject(id) {
    await this.request(
      HttpMethods.DELETE,
      Helpers.getParameterizedString(endpoints.projects.item, { id }),
      {},
      {}
    );
  }

  async updateProject({
    id,
    name,
    description,
    instanceId,
    instanceIpAddress,
    user,
  }) {
    const { data } = await this.request(
      HttpMethods.PATCH,
      Helpers.getParameterizedString(endpoints.projects.item, { id }),
      {},
      {
        name,
        description,
        instance_id: instanceId,
        instance_ip_address: instanceIpAddress,
        user_id: user.id,
      }
    );
    return data;
  }

  async resetProjectIpAddress({ id }) {
    await this.request(
      HttpMethods.PATCH,
      Helpers.getParameterizedString(endpoints.projects.resetItem, { id }),
      {},
      {}
    );
  }

  async getProject(id) {
    const {
      data: { name, description, client, instance_id, user, instance },
    } = await this.request(
      HttpMethods.GET,
      Helpers.getParameterizedString(endpoints.projects.item, { id })
    );
    return mapProjectApiModelToEntity({
      id,
      name,
      description,
      client,
      instance_id,
      instance,
      user,
    });
  }

  /**
   * Licenses block
   */
  async getLicenseFile(id) {
    const data = await this.requestRaw({
      method: HttpMethods.GET,
      responseType: "arraybuffer",
      url: Helpers.getParameterizedString(endpoints.licenses.item.file, { id }),
      timeout: 30 * SECOND,
    });
    return new Blob([data]);
  }

  async getLicensesCsvReport() {
    const data = await this.requestRaw({
      method: HttpMethods.GET,
      responseType: "arraybuffer",
      url: endpoints.licenses.report.csv,
      timeout: 60 * SECOND,
    });
    return new Blob([data]);
  }

  async getLicensesReport() {
    const { data } = await this.request(
      HttpMethods.GET,
      endpoints.licenses.report.json
    );

    return [...data];
  }

  async getLicensesByProject(paginationOptions, sorting, filtering, projectId) {
    const {
      data,
      pagination: { page, total },
    } = await this.request(
      HttpMethods.GET,
      Helpers.getParameterizedString(endpoints.licenses.byProject, {
        projectId,
      }),
      preparePaginationParams({
        pagination: paginationOptions,
        sorting,
        filtering,
      })
    );

    return {
      items: data.map(mapLicenseApiModelToEntity),
      totalItems: total,
      page,
    };
  }

  async createLicense({
    isEnabled,
    description,
    features,
    startAt,
    expireAt,
    expiringWarningPeriod,
    expiredPeriod,
    prolongationUrl,
    licenseType,
    projectId,
  }) {
    const { data } = await this.request(
      HttpMethods.PUT,
      endpoints.licenses.index,
      {},
      mapLicenseEntityToApiModel({
        isEnabled,
        description,
        features,
        startAt,
        expireAt,
        expiringWarningPeriod,
        expiredPeriod,
        prolongationUrl,
        licenseType,
        projectId,
      })
    );
    return data;
  }

  async deleteLicense(id) {
    await this.request(
      HttpMethods.DELETE,
      Helpers.getParameterizedString(endpoints.licenses.item.index, { id }),
      {},
      {}
    );
  }

  async updateLicense({
    id,
    isEnabled,
    description,
    features,
    startAt,
    expireAt,
    expiringWarningPeriod,
    expiredPeriod,
    prolongationUrl,
  }) {
    const { data } = await this.request(
      HttpMethods.PATCH,
      Helpers.getParameterizedString(endpoints.licenses.item.index, { id }),
      {},
      mapLicenseEntityToApiModel({
        isEnabled,
        description,
        features,
        startAt,
        expireAt,
        expiringWarningPeriod,
        expiredPeriod,
        prolongationUrl,
      })
    );
    return data;
  }

  async getLicense(id) {
    const {
      data: {
        is_enabled,
        description,
        features,
        start_at,
        expire_at,
        expiring_warning_period,
        expired_period,
        prolongation_url,
        created_at,
        updated_at,
        license_type,
        user,
      },
    } = await this.request(
      HttpMethods.GET,
      Helpers.getParameterizedString(endpoints.licenses.item.index, { id })
    );

    return mapLicenseApiModelToEntity({
      id,
      is_enabled,
      description,
      features,
      start_at,
      expire_at,
      expiring_warning_period,
      expired_period,
      prolongation_url,
      created_at,
      updated_at,
      license_type,
      user,
    });
  }

  async request(
    method,
    url,
    params = {},
    data = null,
    headers = {},
    timeout = 3 * SECOND
  ) {
    let config = {
      method,
      params,
      url,
      timeout,
      headers: {
        [CONTENT_TYPE_HTTP_HEADER]: CONTENT_TYPE_HTTP_JSON,
      },
    };
    for (const [k, v] of Object.entries(headers)) {
      config.headers[k] = v;
    }
    config.data = data;
    return await this.requestRaw(config);
  }

  async requestRaw(config) {
    if (this.authToken) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers[AUTHORIZATION_HTTP_HEADER] = `Bearer ${this.authToken}`;
    }
    return (await this.httpClient.request(config)).data;
  }
}

export default BackendHttpApi;
